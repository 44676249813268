import React, { useState } from "react";
import { useForm } from "../hooks/useForm";
import "../assets/css/contactUs.css";
import "../assets/css/Modal.css";
import { FormMessage } from "./FormMessage";
import axios from "axios";
import checkGreen from "../assets/img/circleCheckGreen.svg";
import { Modal } from './Modal';
import { useModal } from '../hooks/useModal';
import { ModalBody, ModalHeader } from 'reactstrap';

export const ContactUs = () => {
  
  const initialForm = {
    fullName: "",
    email: "",
    phone: "",
  };

  const [formValues, handleInputChange, reset] = useForm(initialForm);
  const [correctValidation, setCorrectValidation] = useState(0);
  const date = new Date ();
  const [year, month, day] = [date.getFullYear(), date.getMonth() + 1, date.getDate()];
  const [hour, minutes] = [date.getHours(), date.getMinutes()];
  const [isOpenModal, openModal, closeModal] = useModal(false);
  const [loading, setLoading] = useState(false);
  const [region, setregion] = useState('')


  // Tomo valores de la query
  const utms = window.location.search.substring(1).split("&");
  const utm_source = utms[0] ? utms[0].split("=").pop() : "Organic";
  const utm_medium = utms[1] ? utms[1].split("=").pop() : "Organic";
  const utm_campaign = utms[2] ? utms[2].split("=").pop() : "Organic";
  const utm_term = utms[3] ? utms[3].split("=").pop() : "Organic";
  const utm_content = utms[4] ? utms[4].split("=").pop() : "Organic";

   // Expresion regular para validar el email
   const validar_email = (email) => {
    if (/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(email)){
      return true
    } else {
      return false
    }
  }

  const validationsForm = async (form, region) => {
    if (!form.fullName.trim()) {
      setCorrectValidation(5);
      setLoading(false);
      setTimeout(() => {
        setCorrectValidation(0);
      }, 4000);
      return false
    }
    if (form.phone.trim()) {
      if (form.phone.length > 8 && form.phone.length < 13){
        setCorrectValidation(0);
      }else{
        setCorrectValidation(2);
        setLoading(false);
        setTimeout(() => {
          setCorrectValidation(0);
        }, 4000);
        return false
      }
    }else{
      setCorrectValidation(2);
      setLoading(false);
        setTimeout(() => {
          setCorrectValidation(0);
        }, 4000);
        return false
    }
    if (region!=="") {
      setCorrectValidation(0);
    }else{
      setCorrectValidation(6);
      setLoading(false);
        setTimeout(() => {
          setCorrectValidation(0);
        }, 4000);
        return false
    }
    return true
};

  const handleSubmit = async (e) => {
    e.preventDefault(); // Evito que se recargue la pagina
    setLoading(true);
    const validation = validationsForm(formValues, region)

    // Verifico si el email es valido.
    const email_validado = validar_email(formValues.email)
    if (email_validado !== true){
      setCorrectValidation(3);
      setLoading(false);
      setTimeout(() => {
        setCorrectValidation(0);
      }, 4000);
      return false;
    }

    let lead = {
      email: formValues.email,
      status: "New",
      phone_fax: formValues.email,
      first_name: formValues.fullName,
      phone_home: formValues.phone,
      utm_term_c: utm_term,
      description: "",
      lead_source: utm_source,
      date_entered: year + "-" + month + "-" + day + " " + hour + ":" + minutes,
      phone_mobile: formValues.phone,
      utm_medium_c: utm_medium,    
      utm_source_c: utm_source,
      utm_content_c: utm_content,
      utm_campaign_c: utm_campaign,
      assigned_user_id: "821db49c-70f4-8040-d6f9-62ff989b79b5",
      identification_c: "-",
      primary_address_state: region
    };

    // Si todo esta correcto, lo envio
    if (
      validation && email_validado
    )
    {
      await axios({
        method: "POST",
        url: "processor.php",
        headers: {
          "Content-Type": "application/json",
        },
        data: lead,
      })
        .then((res) => {
          if (res.data.includes("success")) {
            openModal()
            setLoading(false);
            setTimeout(function(){
              closeModal();
            },5000);            
          }
        })
        .catch((err) => {
          setCorrectValidation(4); // "A ocurrido un error inesperado"
          setLoading(false);
          setTimeout(() => {
            setCorrectValidation(0);
          }, 4000);
        });

      setTimeout(() => {
        setCorrectValidation(0);
      }, 4000);
      reset();
      return;
    }
  };

  return (
    <div className="contact-us" id="contact-us">
      <div className="contact-us-container">
        <h2 className="contact-us-container-title">
        PREENCHA O FORMULÁRIO ABAIXO E TE LIGAMOS EM SEGUIDA!
        </h2>
        {correctValidation !== 0 ? (
          <div className="message">
            <FormMessage correctValidation={correctValidation} />
          </div>
        ):(
          <span></span>
        )
        }
        <form onSubmit={handleSubmit} className="form-contact" id="msform">
          <label>Nome</label>
          <input
            className="input-name"
            type="text"
            name="fullName"
            id="fullName"
            value={formValues.fullName}
            onChange={handleInputChange}
            required
          />
          <label>E-mail</label>
          <input
            className="input-email"
            type="email"
            name="email"
            id="email"
            value={formValues.email}
            onChange={handleInputChange}
            required
          />
          <label>Telefone</label>
          <div className="input-phone-content">
            <input
              className="input-phone"
              type="number"
              name="phone"
              id="phone"
              value={formValues.phone}
              onChange={handleInputChange}
              required
            />
          </div>
          <label>Onde está localizada a propiedade?</label>
          <div className="dropdown-container">
              <select
                name="region"
                placeholder="selecione uma região"
                value={region}
                onChange={(e) => setregion(e.target.value)}
                required
              >
                <option disabled hidden value="">Selecione uma região</option>
                <option value="norte_de_são_paulo">Norte de São Paulo</option>
                <option value="sul_de_são_paulo">Sul de São Paulo</option>
                <option value="leste_de_são_paulo">Leste de São Paulo</option>
                <option value="oeste_de_são_paulo">Oeste de São Paulo</option>
                <option value="rio_de_janeiro">Rio de Janeiro</option>
              </select>
          </div>
          <br />
          <div className="checkbox-container">
            {loading ? (
                <button type="submit" disabled>
                  <span
                    className="spinner-border spinner-border-md"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  {"      "}
                  Em processo
                </button>
              ) : (
                <button 
                type='Submit'
                id="contactUs.button">
                  Enviar
                </button>
              )}
          </div>
          <div>
            <Modal isOpen={isOpenModal} closeModal={closeModal}>
                <ModalHeader style={{display: 'block', borderBottom:'#fff'}}>
                    <span style={{float: 'right'}}></span>
                </ModalHeader>
                <ModalBody>
                  <div className="checkGreen">
                    <img src={checkGreen} className="checkGreen-container" alt="checkGreen"/>
                  </div>
                  <h1 className="thanks-title">
                    Muito obrigado!
                  </h1>
                  <h2 className='thanks-sub-title'>
                    Entraremos em contato em breve.
                  </h2>
                </ModalBody>
            </Modal>
          </div>
        </form>
      </div>
    </div>
  );
};
