import React from 'react'
import Carousel from "react-bootstrap/Carousel";
import "../assets/css/smartSecurity.css";
import smartApp from "../assets/img/CameraInteligenteWifi.png";
import smartSet from "../assets/img/AlarmeMonitorado.png";
import fullSet from "../assets/img/alerme-camera-1.png";

const AppSmartSecurity = () => {
  return (
    <div className='smart-security'>
      <div className='smart-security-content'>
        <Carousel>
          <Carousel.Item>
            <div className='smart-security-img-position'>
              <img
                  src={smartApp}
                  alt='app-smart-security'
                  className='smartapp-image'
              />
              <div className='smart-security-position'>
                <h1>
                CÂMERA INTELIGENTE WIFI
                </h1>
                <h3>
                Plano ideal para quem quer ver o que o que acontece na sua casa ou apê.
                </h3>
                <h4>
                Você está sempre preocupado com o que acontece no seu imóvel quando não está por perto? Esse plano pode te ajudar!
                </h4>
                <ul>
                  <li>Câmera inteligente WIFI para ambientes internos em comodato. </li>
                  <li>Aplicativo ADT Smart Security para visualização das imagens ao vivo.</li>
                  <li>Manutenção e suporte para uso da câmera e aplicativo.</li>
                  <li>Placa de “Protegido por ADT”.</li>
                  <li>Suporte para seus dispositivos digitais, rede de internet e pacote office.</li>
                  <li>5 licenças do Bitdefender para controle parental, antivírus e localização de dispositivos móveis.</li>
                  <li>Recurso para criação de regras para gravação de vídeos e notificações.</li>
                </ul>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className='smart-security-img-position'>
              <img
                  src={smartSet}
                  alt='smart-Set-security'
                  className='smartset-image'
              />
              <div className='smart-security-position'>
                <h1>
                ALARME MONITORADO
                </h1>
                <h3>
                Plano ideal para quem procura protecao contra invasões.
                </h3>
                <h4>
                Tranquilidade é saber que seu imóvel está seguro com a ADT e que você será avisado em caso de tentativa de invasão!
                </h4>
                <ul>
                  <li>Todos os produtos de alarme descritos abaixo em comodato.</li>
                  <li>Monitoramento do seu imóvel 24hs por dia.</li>
                  <li>Aplicativo ADT Smart Security para ativar e desativar seu alarme à distância.</li>
                  <li>Manutenção e suporte do seu alarme e aplicativo.</li>
                  <li>Relatório de abertura e fechamento do imóvel.</li>
                  <li>Suporte para seus dispositivos digitais, rede de internet e pacote office.</li>
                  <li>5 licenças do Bitdefender para controle parental, antivírus e localização de dispositivos móveis.</li>
                  <li>Recurso para criação de regras de notificações de acordo com a rotina de uso do alarme.</li>
                  <li>Placa de “Protegido por ADT”.</li>
                </ul>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className='smart-security-img-position'>
              <img
                  src={fullSet}
                  alt='fullSet-security'
                  className='smartset-image'
              />
              <div className='smart-security-position'>
                <h1>
                ALARME MONITORADO + CAMERA
                </h1>
                <h3>
                Plano ideal para quem procura por seguranca dentro e fora do imovel.
                </h3>
                <h4>
                Além de proteger seu imóvel contra invasão, fique por dentro de tudo que acontece no ambiente, combinando as açoes entre o alarme e as cameras.
                </h4>
                <ul>
                  <li>Todos os produtos descritos abaixo em comodato.</li>
                  <li>Monitoramento do seu imóvel 24hs por dia.</li>
                  <li>Aplicativo ADT Smart Security para ativar e desativar seu alarme à distância e ver as imagens ao vivo.</li>
                  <li>Recurso para criação de regras para gravação de vídeos e envio de notificação baseado na rotina do local.</li>
                  <li>Relatório de abertura e fechamento do imóvel.</li>
                  <li>Suporte para seus dispositivos digitais, rede de internet e pacote office.</li>
                  <li>5 licenças do Bitdefender para controle parental, antivírus e localização de dispositivos móveis.</li>
                  <li>Manutenção e suporte para uso da câmera, alarme e aplicativo.</li>
                  <li>Placa de “Protegido por ADT”.</li>
                </ul>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  )
}

export default AppSmartSecurity